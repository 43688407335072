import React from "react";
import styled from "styled-components";

const StyledParagraph = styled.p`
	font-size: ${({ fontSize }) => fontSize || "20px"};
	font-weight: ${({ fontWeight }) => fontWeight || "300"};
	color: ${({ color }) => color || "black"};
	margin: ${({ margin }) => margin || "20px 0"};
	padding: ${({ padding }) => padding || "0"};
	width: ${({ width }) => width || "8px 0"};
	text-decoration: ${({ textDecoration }) => textDecoration || "none"};
	text-align: ${({ textAlign }) => textAlign || "left"};
	text-justify: ${({ textJustify }) => textJustify || "auto"};
`;

const Paragraph = ({ children, ...props }) => {
	return <StyledParagraph {...props}>{children}</StyledParagraph>;
};

export default Paragraph;
