import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet"
import Prismic from "../../api/Prismic";
import { Paragraph } from "../../components";
import styled from 'styled-components'
import { RichText } from "prismic-reactjs";

const StyledImage = styled.img`
  height: 400px;
  margin-bottom: 12px;
`

const AboutWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  p {
    margin-bottom: 12px;
  }
`

const AboutView = () => {
  const [image, setImage] = useState([])
  const [text, setText] = useState([])

  useEffect(() => {
    const fetchData = () => {
      Prismic.getAboutPage().then(data => {
        setText(data[0].text)
        setImage(data[0].image)
      })
    }

    fetchData()
  }, []);

	return (
		<AboutWrapper>
			<Helmet>
				<title>Alex || About</title>
			</Helmet>
      <StyledImage src={image.url} />
      <Paragraph margin="12px 0">
        {RichText.render(text)}
      </Paragraph>
		</AboutWrapper>
	);
};

export default AboutView;
