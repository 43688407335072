import React from "react";
import App from "./App";
import styled from "styled-components";

import { Header } from "./components";

const Container = styled.div`
  display: -webkit-flex;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  padding: 0 12px;
  box-sizing: border-box;
  width: 100%;
  
  @media (min-width: 500px) {
    margin: 0 auto;
  }
`;

const Layout = () => {
  return (
    <Container>
      <Content>
        <Header />
        <App />
      </Content>
    </Container>
  );
};

export default Layout;
