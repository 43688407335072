import React from 'react'
import { RichText } from "prismic-reactjs";
import styled from "styled-components"
import mediaQueries from "../../assets/theme/mediaQueries"
import moment from "moment"

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  :not(:first-child) {
    border-top: 1px solid grey;
  }

  @media ${mediaQueries.tabletPortraitDown} {
    flex-direction: column;
    align-items: start;
  }
`

const DateWrapper = styled.div`
  width: 150px;
  display: flex;
  align-items: center;

  @media ${mediaQueries.tabletPortraitDown} {
    height: 60px;
  }
`

const DateDay = styled.p`
  font-size: 50px;
  font-weight: 700;
  margin-right: 12px;
  color: #4f8928;
`

const MonthYearWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DateMonth = styled.p`
  font-size: 18px;
`

const DateYear = styled.p`
  font-size: 18px;
`

const Description = styled.p`
  width: 500px;
  font-weight: 700;
  margin: 8px 0;

  @media ${mediaQueries.tabletPortraitDown} {
    width: 100%;
  }
`

const Location = styled.p`
  width: 500px;
  margin: 8px 0;

  @media ${mediaQueries.tabletPortraitDown} {
    width: 100%;
  }
`

const StyledLink = styled.button`
  height: 36px;
  width: 136px;
  border-radius: 5px;
  background-color: #4f8928;
  color: white;
  border: 0;
  cursor: pointer;
  margin: 8px 0;
`

const EventItem = ({ event, upcoming }) => {
  const date = moment(event.date);
  const day = date.format("D")
  const month = date.format("MMM")
  const year = date.format("YYYY")

  return (
    <>
      <ItemWrapper>
        <DateWrapper>
          <DateDay>{ day }</DateDay>
          <MonthYearWrapper>
            <DateMonth>{ month }</DateMonth>
            <DateYear>{ year }</DateYear>
          </MonthYearWrapper>
        </DateWrapper>
        <div>
          <Description>{RichText.asText(event.description) }</Description>
          <Location>{RichText.asText(event.event_location) }</Location>
        </div>
        { (!event.is_free && upcoming) ? (
          <a href={event.link_to_tickets.url}
            target="_blank"
            rel="noopener noreferrer"
            class="button"
          >
            <StyledLink
              href={event.link_to_tickets.url}
              target="_blank"
              class="button"
            >
              Buy tickets
            </StyledLink>
          </a>
        ) : <></> }
      </ItemWrapper>
    </>
  )
}

export default EventItem
