import styled from "styled-components";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import IosArrowBack from "react-ionicons/lib/IosArrowBack";
import colors from "../../assets/theme/colors";
import { ButtonBack, ButtonNext } from "pure-react-carousel";

import mediaQueries from "../../assets/theme/mediaQueries";

export const MediaViewWrapper = styled.div`
	width: 100%;

	p {
		padding: 8px 0;
	}

	.embed-youtube {
		text-align: center;
	}

	iframe {
		width: 80%;
		height: 500px;
	}

	@media ${mediaQueries.mobileOnly} {
		iframe {
			width: 100%;
		}
	}
`;

export const ArrowsWrapper = styled.div`
	width: 100%;
	position: relative;
`;

export const BackButton = styled(ButtonBack)`
	background: none;
	outline: none;
	border: none;
	margin: 0;
	padding: 0;

	position: absolute;
	left: 0;
	top: 50%;
	z-index: 100;
`;

export const NextButton = styled(ButtonNext)`
	background: none;
	outline: none;
	border: none;
	margin: 0;
	padding: 0;

	position: absolute;
	right: 0;
	top: 50%;
	z-index: 100;
`;

export const BackArrow = styled(IosArrowBack)`
	fill: black;
	transition: fill 0.1s;

	&:hover {
		fill: ${colors.primary};
	}
`;

export const NextArrow = styled(IosArrowForward)`
	fill: black;
	transition: fill 0.1s;

	&:hover {
		fill: ${colors.primary};
	}
`;

export const ImageWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const StyledImage = styled.img`
	width: 80%;
`;
