import React from "react"
import { RichText } from "prismic-reactjs";
import styled from "styled-components";
import mediaQueries from "../../assets/theme/mediaQueries"

const ItemWrapper = styled.div`
  margin-bottom: 25px;

  p {
    margin-bottom: 12px;
  }

  iframe {
		width: 100%;
    height: 500px;
    margin: 12px 0;
	}

	@media ${mediaQueries.mobileOnly} {
		iframe {
			height: 200px;
		}
	}
`

const TitleLink = styled.a`
  height: 20px;
`

const StyledImage = styled.img`
  height: 400px;
  margin: 0 2px;

  @media ${mediaQueries.tabletLandscapeDown} {
    width: 100%;
    height: auto;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .img {
    flex: 0 0 auto;
  }
`

const EnsembleItem = ({ ensemble }) => {
  const { images } = ensemble;

  return (
    <ItemWrapper>
      <TitleLink href={ensemble.link_to_ensemble.url} target="_blank" rel="noopener noreferrer">
        <h1>{ RichText.asText(ensemble.ensemble_title) }</h1>
      </TitleLink>
      <p>{ RichText.render(ensemble.ensemble_description) }</p>

      <ImageWrapper>
        {ensemble.images.length > 1 && images.map((image, i) => {
          return <StyledImage src={image.image1.url} key={`image ${i}`} />
        })}
      </ImageWrapper>
    </ItemWrapper>
  )
}

export default EnsembleItem;
