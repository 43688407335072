import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Prismic from "../../api/Prismic";
import { EventList } from "../../components";

const UpcomingEventsWrapper = styled.div`
  width: 100%;
  margin-bottom: 25px;
`

const PastEventsWrapper = styled.div`
  width: 100%;
`

const EventsView = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    Prismic.getAllEvents().then((events) => {
      return setEvents(events)
    });
  }, [])

  return (
    <>
      <Helmet>
        <title>Alex || Events</title>
      </Helmet>

      <UpcomingEventsWrapper>
        <h1>Upcoming Events</h1>
        {events.length > 0 &&
          <EventList events={
            events.filter(event => new Date(event.date) >= new Date())
          } upcoming={true} />
        }
      </UpcomingEventsWrapper>

      <PastEventsWrapper>
        <h1>Past Events</h1>
        {events.length > 0 &&
          <EventList events={
            events.filter(event => new Date(event.date) < new Date())
          } upcoming={false} />
        }
      </PastEventsWrapper>
    </>
  );
};

export default EventsView;
