import React from "react";
import styled from "styled-components";

export const StyledH1 = styled.h1`
  color: ${({ color }) => color || "black"};
  font-size: 50px;
  margin: ${({ margin }) => margin || "0"};
  border-bottom: ${({ borderBottom }) => borderBottom || "none"};
  font-weight: ${({ fontWeight }) => fontWeight || "500"};
`;

const StyledH2 = styled.h2`
  color: ${({ color }) => color || "black"};
  padding-top: 20px;
  font-size: ${({ fontSize }) => fontSize || "35px"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  border-bottom: ${({ borderBottom }) => borderBottom || "none"};
`;

const Title = ({ type, copy, color, ...props }) => {
  switch (type) {
    case "h1":
      return (
        <StyledH1 color={color} {...props}>
          {copy}
        </StyledH1>
      );
    case "h2":
      return (
        <StyledH2 color={color} {...props}>
          {copy}
        </StyledH2>
      );
    default:
      return console.warn(
        "Title component used without type tag. Please provide H value"
      );
  }
};

export default Title;
