const mediaQueries = {
  mobileOnly: `(max-width: 599px)`,
  tabletPortraitUp: `(min-width: 600px)`,
  tabletPortraitDown: `(max-width: 750px)`,
  tabletLandscapeUp: `(min-width: 960px)`,
  tabletLandscapeDown: `(max-width: 960px)`,
  desktopDown: `(max-width: 1080px)`,
  desktopUp: `(min-width: 1080px)`,
  bigDesktopUp: `(min-width: 1800px)`,
};

export default mediaQueries;
