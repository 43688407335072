import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  HomeView,
  AboutView,
  EventsView,
  EnsemblesView,
  MediaView,
  MediaItemView,
  ContactView,
} from "./views";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={HomeView} />
      <Route exact path="/about" component={AboutView} />
      <Route exact path="/events" component={EventsView} />
      <Route exact path="/ensembles" component={EnsemblesView} />
      <Route exact path="/media" component={MediaView} />
      <Route exact path="/media/:uid" component={MediaItemView} />
      <Route exact path="/contact" component={ContactView} />
    </Switch>
  );
};

export default Routes;
