import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Prismic from "../../api/Prismic";

const StyledImage = styled.img`
  height: 500px;
  margin: 0 2px;
`;

const HomeViewWrapper = styled.div`
  width: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .img {
    flex: 0 0 auto;
  }
`

const HomeView = () => {
  const [images, setImages] = useState([])

  useEffect(() => {
    Prismic.getHomePage().then(data => {
      setImages(data[0].images)
    })
  }, []);

  return (
    <HomeViewWrapper>
      <Helmet>
        <title>Alex || Home</title>
      </Helmet>

      <ImageWrapper>
        {images.length > 1 && images.map(({ image }, i) => {
          return <StyledImage src={image.url} />
        })}
      </ImageWrapper>
    </HomeViewWrapper>
  );
};

export default HomeView;
