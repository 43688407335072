import React, { useEffect, useState } from 'react'
import Prismic from "../../api/Prismic";
import EnsemblesList from '../../components/EnsemblesList';

const EnsemblesView = () => {
  const [loading, setLoading] = useState(false);
  const [ensemblesArray, setEnsemblesArray] = useState(null);

  useEffect(() => {
    setLoading(true);

    const fetchData = () => {
      Prismic.getAllEnsembles().then((ensembles) => {
        setEnsemblesArray(ensembles);
        setLoading(false);
      });
    }

    fetchData();
  }, [setLoading]);

  return (
    <>
      { loading && <p>Loading...</p> }
      { ensemblesArray && <EnsemblesList ensembles={ensemblesArray} /> }
    </>
  )
}

export default EnsemblesView;