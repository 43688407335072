import React from "react";
import { withRouter } from "react-router-dom";
import { RichText } from "prismic-reactjs";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import {
  MediaItemCardWrapper,
  StyledImage,
  StyledTitle,
  TextBox,
  TextWrapper
} from "./MediaItemCard.styles";

const MediaItemCard = ({ mediaItem, history }) => {
  return (
    <MediaItemCardWrapper
      onClick={() => history.push(`/media/${mediaItem.uid}`)}
    >
      <StyledImage src={mediaItem.thumbnail.url} />
      <TextBox>
        <TextWrapper>
          <StyledTitle
            type="h2"
            copy={RichText.asText(mediaItem.media_title)}
            fontSize="32px"
          />
          <IosArrowForward fontSize="45px" />
        </TextWrapper>
      </TextBox>
    </MediaItemCardWrapper>
  );
};

export default withRouter(MediaItemCard);
