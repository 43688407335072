import React from 'react'
import EventItem from '../EventItem'

const EventList = ({ events, upcoming }) => {
  const sortedEvents = () => {
    events.sort((a,b) => {
      return new Date(b.date) - new Date(a.date);
    });
    return upcoming ? events.reverse() : events
  }

  return (
    <div>
      { events && sortedEvents().map(event => <EventItem event={event} upcoming={upcoming} />) }
    </div>
  )
}

export default EventList
