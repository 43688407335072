import Prismic from "prismic-javascript";
const apiEndpoint = "https://alex-portfolio.cdn.prismic.io/api/v2";
const accessToken = "";

const Client = Prismic.client(apiEndpoint, { accessToken });

class PrismicClass {
	// *** About Me API *** //
	static async getAboutPage() {
		const response = await Client.query(
			Prismic.Predicates.at("document.type", "about_page")
		);
		return response.results.map((item) => ({
			...item.data,
			uid: item.uid,
			id: item.id,
		}));
	}

	// *** Contact Us API *** //
	static async getContactPage() {
		const response = await Client.query(
			Prismic.Predicates.at("document.type", "contact_page")
		);
		return response.results.map((item) => ({
			...item.data,
			uid: item.uid,
			id: item.id,
		}));
	}

  // *** Ensembles API *** //
	static async getHomePage() {
		const response = await Client.query(
			Prismic.Predicates.at("document.type", "homepage")
		);
		return response.results.map((item) => ({
			...item.data,
			uid: item.uid,
			id: item.id,
		}));
	}

	// *** Ensembles API *** //
	static async getAllEnsembles() {
		const response = await Client.query(
			Prismic.Predicates.at("document.type", "ensemble")
		);
		return response.results.map((item) => ({
			...item.data
		}));
	}
  
  // *** Events API *** //
	static async getAllEvents() {
		const response = await Client.query(
			Prismic.Predicates.at("document.type", "event")
		);
		return response.results.map((item) => ({
			...item.data,
			uid: item.uid,
			id: item.id,
		}));
	}

	static async getEventItemById(id) {
		const response = await Client.query([
			Prismic.Predicates.at("document.type", "event"),
			Prismic.Predicates.at("my.event.uid", id),
		]);
		const [result] = response.results;

		return { ...result.data, id: result.id, uid: result.uid };
	}
  
  // *** MediaItem API *** //
	static async getAllMedia() {
		const response = await Client.query(
			Prismic.Predicates.at("document.type", "media")
		);
		return response.results.map((item) => ({
			...item.data,
			uid: item.uid,
			id: item.id,
		}));
	}

	static async getMediaItemById(id) {
		const response = await Client.query([
			Prismic.Predicates.at("document.type", "media"),
			Prismic.Predicates.at("my.media.uid", id),
		]);
		const [result] = response.results;

		return { ...result.data, id: result.id, uid: result.uid };
	}

	static async getImageById(id) {
		const response = await Client.query([
			Prismic.Predicates.at("document.type", "image"),
			Prismic.Predicates.at("my.image.uid", id),
		]);
		const [result] = response.results;

		return { ...result.data, id: result.id, uid: result.uid };
	}

	static linkResolver(doc) {
		if (doc.type === "media") {
			return `/media/${doc.uid}`;
		}
	}
}

export default PrismicClass;
