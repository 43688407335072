import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Prismic from "../../api/Prismic";
import { RichText } from "prismic-reactjs";
import { Title, Paragraph } from "../../components";
import {
	MediaViewWrapper,
	ImageWrapper,
	StyledImage,
	ArrowsWrapper,
	BackArrow,
	NextArrow,
	BackButton,
	NextButton,
} from "./MediaItemView.styles";
import { Slider, Slide, CarouselProvider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const MediaView = () => {
	const [loading, setLoading] = useState(false);
	const [mediaItem, setMediaItem] = useState(null);
	const params = useParams();

	//for responsive slider
	const isDesktop = useMediaQuery({
		query: "(min-device-width: 1080px)",
	});
	const slideCount = isDesktop ? 2 : 1;

	useEffect(() => {
		setLoading(true);
		Prismic.getMediaItemById(params.uid).then((data) => {
			setMediaItem(data);
			setLoading(false);
		});
	}, [setLoading, params.uid]);

	return (
		<MediaViewWrapper>
			{loading && <p>loading...</p>}
			{mediaItem && (
				<>
					<Helmet>
						<title>
							Alex || {mediaItem ? RichText.asText(mediaItem.media_title) : ""}
						</title>
					</Helmet>
					<Title type="h1" copy={RichText.asText(mediaItem.media_title)} />
					<Paragraph margin="12px 0">
						{RichText.render(mediaItem.media_description)}
					</Paragraph>

					{mediaItem.images.length > 1 && (
						<CarouselProvider
							naturalSlideWidth={100}
							naturalSlideHeight={100}
							totalSlides={mediaItem.images.length}
							visibleSlides={slideCount}
							infinite={true}
							isIntrinsicHeight={true}
							touchEnabled={true}
							isPlaying={true}
							style={{ marginBottom: "50px" }}
						>
							<ArrowsWrapper>
								<BackButton>
									<BackArrow fontSize="30px" />
								</BackButton>
								<NextButton>
									<NextArrow fontSize="30px" />
								</NextButton>
								<Slider>
									{mediaItem.images.map(({ image }, i) => {
										return (
											<Slide index={i}>
												<ImageWrapper>
													<StyledImage src={image.url} alt={image.alt} />
												</ImageWrapper>
											</Slide>
										);
									})}
								</Slider>
							</ArrowsWrapper>
						</CarouselProvider>
					)}
				</>
			)}
		</MediaViewWrapper>
	);
};

export default MediaView;
