import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Prismic from "../../api/Prismic";
import { MediaList } from "../../components";

const MediaViewWrapper = styled.div`
  width: 100%;
`;

const MediaView = () => {
  const [mediaArray, setMediaArray] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true)

    const fetchData = () => {
      Prismic.getAllMedia().then((media) => {
        setMediaArray(media)
        setLoading(false)
      });
    }

    fetchData();
  }, []);

  return (
    <MediaViewWrapper>
      <Helmet>
        <title>Alex || Media</title>
      </Helmet>
      { loading && <p>Loading...</p> }
      { mediaArray && <MediaList media={mediaArray} /> }
    </MediaViewWrapper>
  );
};

export default MediaView;
