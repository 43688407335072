import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Paragraph } from "../../components";
import LogoYoutube from "react-ionicons/lib/LogoYoutube";
import IosMail from "react-ionicons/lib/IosMail";
import colors from "../../assets/theme/colors";
import Prismic from "../../api/Prismic";
import { RichText } from "prismic-reactjs";

const ContactWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SocialsWrapper = styled.div`
  display: flex;

  svg {
    fill: black;
    transition: fill 0.3s;
    font-size: 20px;
    &:hover {
      fill: ${colors.primary};
    }
  }
`;

const StyledImage = styled.img`
  height: 400px;
`

const ContactView = () => {
  const [image, setImage] = useState([])
  const [text, setText] = useState([])

  useEffect(() => {
    Prismic.getContactPage().then(data => {
      setText(data[0].text)
      setImage(data[0].image)
    })
  }, []);

  return (
    <ContactWrapper>
      <Helmet>
        <title>Alex || Contact</title>
      </Helmet>

      <StyledImage src={image.url} />

      <Paragraph margin="12px 0">
        {RichText.render(text)}
      </Paragraph>
      <SocialsWrapper>
        <a
          href="https://www.youtube.com/channel/UC-hGE7GRX1GykB6glJq6tZA/featured"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LogoYoutube fontSize="40px" />
        </a>

        <a href="mailto:alexandralyonmusic@gmail.com">
          <IosMail fontSize="50px" />
        </a>
      </SocialsWrapper>


    </ContactWrapper>
  );
};

export default ContactView;
