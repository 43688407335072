import styled from "styled-components"
import mediaQueries from "../../assets/theme/mediaQueries";
import colors from "../../assets/theme/colors";
import { Title } from "..";

export const MediaItemCardWrapper = styled.div`
  position: relative;
  max-width: 500px;
  min-width: 500px;
  margin: 40px 0;
  transition: opacity 0.3s;
  cursor: pointer;
  border-radius: 2px;

  h2 {
    transition: color 0.3s;
  }

  svg {
    transition: fill 0.3s;
  }

  &:hover {
    opacity: 0.75;

    svg {
      fill: ${colors.primary};
    }

    h2 {
      color: ${colors.primary};
    }
  }

  @media ${mediaQueries.tabletLandscapeDown} {
    min-width: 550px;
    max-width: 550px;

    svg {
      width: 32px;
    }
  }

  @media ${mediaQueries.mobileOnly} {
    min-width: 100%;
    max-width: 100%;

    svg {
      width: 32px;
    }
  }

  @media ${mediaQueries.tabletLandscapeUp} {
    min-width: 400px;
    max-width: 400px;

    svg {
      width: 40px;
    }
  }

  @media ${mediaQueries.desktopUp} {
    min-width: 500px;
    max-width: 500px;
  }
`;

export const TextBox = styled.div`
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  opacity: 0.9;
  height: 33%;
  width: 100%;
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
`;

export const StyledImage = styled.img`
  width: 100%;
  border-radius: 2px;
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled(Title)`
  @media ${mediaQueries.mobileOnly} {
    font-size: 20px;
  }

  @media ${mediaQueries.desktopDown} {
    font-size: 24px;
  }
`;