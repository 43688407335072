import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import "./Header.css";
import Prismic from "../../api/Prismic";
import mediaQueries from "../../assets/theme/mediaQueries";
import aboutTitle from "../../assets/images/about_me_title.png"
import ensemblesTitle from "../../assets/images/ensembles_title.png"
import eventsTitle from "../../assets/images/events_title.png"
import mediaTitle from "../../assets/images/media_title.png"
import contactTitle from "../../assets/images/contact_title.png"
import {useLocation} from "react-router-dom";

const HeaderWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  @media ${mediaQueries.tabletLandscapeDown} {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  @media ${mediaQueries.mobileOnly} {
    a {
      font-size: 20px;
    }
  }
`;

const NavWrapper = styled.div`
  a {
    font-size: 20px;
  }
  @media ${mediaQueries.tabletLandscapeDown} {
    display: none;
  }
`

const StyledImage = styled.img`
  width: 150px;
  margin-right: 20px;
`

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #4f8928;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 100;

  @media (max-width: 576px) {
      width: 100%;
    }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }

  @media ${mediaQueries.tabletLandscapeUp} {
    display: none;
  }
`

const Menu = ({ open }) => {
  return (
    <StyledMenu open={open}>
      <a id="home" href="/">Home</a>
      <a id="about" href="/about">About</a>
      <a id="events" href="/events">Events</a>
      <a id="ensembles" href="/ensembles">Ensembles</a>
      <a id="media" href="/media">Media</a>
      <a id="contact" href="/contact">Contact</a>
    </StyledMenu>
  )
}

const StyledBurger = styled.button`
  position: absolute;
  top: 5%;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => open ? 'black' : 'black'};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }

  @media ${mediaQueries.tabletLandscapeUp} {
    display: none;
  }
`

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

const Header = () => {
  const [loading, setLoading] = useState(false);
	const [image, setImage] = useState(null);
  const [path, setPath] = useState("");
  const [open, setOpen] = React.useState(false);
  const node = React.useRef();

  let location = useLocation()

  useEffect(() => {
		setLoading(true);
		Prismic.getImageById("logo").then((image) => {
			setImage(image);
			setLoading(false);
		});
	}, [setLoading]);

  useEffect(() => {
    if (location.pathname === "/") {
      setPath("home")
    } else if (location.pathname === "/about") {
      setPath("home")
    } else if (location.pathname === "/events") {
      setPath("home")
    } else if (location.pathname === "/ensembles") {
      setPath("home")
    } else if (location.pathname === "/contact") {
      setPath("home")
    } else if (location.pathname === "/media") {
      setPath("home")
    } else {
      setPath("something else")
    }
  }, [location.pathname, path])

  return (
    <HeaderWrapper>
      {loading || !image ? (
				<></>
			) : (
        path === "home" && <StyledImage src={image.image.url} />
      )}

      { path === "about" && <StyledImage src={aboutTitle} /> }
      { path === "ensembles" && <StyledImage src={ensemblesTitle} /> }
      { path === "events" && <StyledImage src={eventsTitle} /> }
      { path === "media" && <StyledImage src={mediaTitle} /> }
      { path === "contact" && <StyledImage src={contactTitle} /> }

      <div ref={node}>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
      </div>
      
      <NavWrapper>
        <NavLink to="/" exact activeClassName="active">
          HOME
        </NavLink>
        <NavLink to="/about" activeClassName="active">
          ABOUT
        </NavLink>
        <NavLink to="/events" activeClassName="active">
          EVENTS
        </NavLink>
        <NavLink to="/ensembles" activeClassName="active">
          ENSEMBLES
        </NavLink>
        <NavLink to="/media" activeClassName="active">
          MEDIA
        </NavLink>
        <NavLink to="/contact" activeClassName="active">
          CONTACT
        </NavLink>
      </NavWrapper>
    </HeaderWrapper>
  );
};

export default Header;
