import React from "react";
import styled from "styled-components";
import { MediaItemCard } from "..";

import mediaQueries from "../../assets/theme/mediaQueries";

const MediaListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${mediaQueries.desktopDown} {
    justify-content: space-evenly;
  }
`;

const MediaList = ({ media }) => {
  return (
    <MediaListWrapper>
      {media.map((mediaItem) => {
        return <MediaItemCard mediaItem={mediaItem} key={mediaItem.id} />;
      })}
    </MediaListWrapper>
  );
};

export default MediaList;
